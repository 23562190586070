.App {
  text-align: center;
}

.flex {
  display: flex;
}

.flex1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}
